import type { AssetsManifest } from '@engined/dev/build/manifest.js';
import React from 'react';
import { RouteObject } from 'react-router-dom';

export interface SSRContextValue<TProps = unknown> {
  cspNonce: string;
  csrfToken: string;
  manifest: AssetsManifest;
  apolloCache: unknown;
  props: TProps;
  sentry?: {
    trace: string;
    baggage?: string;
  };
  routes?: RouteObject[];
}

const defaultContext: SSRContextValue = {
  cspNonce: null,
  csrfToken: null,
  manifest: null,
  apolloCache: null,
  props: null,
};

const SSRContext = React.createContext<SSRContextValue>(defaultContext);

SSRContext.displayName = 'SSRContext';

export default SSRContext;

export function useSSR(): SSRContextValue {
  return React.useContext(SSRContext);
}
